import * as React from 'react'
import MainTemplate from '../templates/MainTemplate'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

const Layout = styled.div`
  width: 90%;
  max-width: 1100px;
  margin: 1rem auto;
`

const PrivacyPolicyPage = () => {
  return (
    <MainTemplate>
      <Helmet>
        <title>Adwokaci Orla 3 | Polityka prywatności</title>
      </Helmet>
      <Layout>
        <div>
          <h2>Polityka prywatności</h2>
          <h3>AdwokaciOrla3.pl</h3>

          <p>
            Administratorem danych osobowych udostępnionych przez Użytkownika w Serwisie
            AdwokaciOrla3.pl jest Adwokat Julianna Kędzierska, prowadząca działalność pod adresem:
            Orla 3, 20-022 Lublin, o nadanym numerze identyfikacji podatkowej (NIP): 9462583101 i
            nadanym numerze REGON: 384207083 (dalej jako „Administrator”).
          </p>
        </div>
        <div>
          <h3>I. ZGODA UŻYTKOWNIKA</h3>
          <p>
            Korzystanie z Serwisu AdwokaciOrla3.pl przez Użytkownika oznacza, że Użytkownik
            akceptuje, że Administrator gromadzi oraz wykorzystuje dane osobowe zgodnie z niniejszą
            Polityką Prywatności. Dane osobowe Użytkownika Serwisu są przetwarzane przez
            Administratora w oparciu o jego zgodę, zaś w niektórych przypadkach opisanych w
            niniejszym dokumencie, w ramach prawnie uzasadnionego interesu Administratora.
            Użytkownik ma prawo w dowolnym momencie wycofać wyrażoną wcześniej zgodę. Wycofanie
            zgody nie wpływa na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody
            przed jej wycofaniem. W przypadku, gdy nastąpi zmiana niniejszej Polityki Prywatności, a
            Użytkownik nadal będzie korzystał z Serwisu, to poczytuje się to za wyrażenie zgody na
            aktualne warunki Polityki Prywatności.
          </p>
        </div>
        <div>
          <h3>II. DANE OSOBOWE PRZETWARZANE PRZEZ ADMINISTRATORA</h3>
          <p>Sposób pozyskiwania danych osobowych.</p>
          <p>
            Administrator pozyskuje dane osobowe bezpośrednio od Użytkownika za pośrednictwem
            Serwisu.
          </p>
          <p>Rodzaje przetwarzanych danych osobowych.</p>
          <ol>
            <li>Imię i nazwisko;</li>
            <li>Adres e-mail;</li>
            <li>Numer telefonu;</li>
            <li>Treść wiadomości;</li>
          </ol>
        </div>
        <div>
          <h3>III. CELE PRZETWARZANIA DANYCH OSOBOWYCH</h3>
          <p>
            Sposób przetwarzania przez Administratora danych dotyczących Użytkownika zależy od
            sposobu korzystania przez Użytkownika z Serwisu i dostępnych w nim funkcjonalności.
            Administrator przetwarza dane osobowe Użytkownika w następujących celach:
          </p>
          <ol>
            <li>
              Realizacji na rzecz Użytkownika usług dostępnych za pośrednictwem Serwisu.
              Administrator wykorzystuje dane osobowe Użytkownika w celu uwierzytelniania oraz
              autoryzowania jego dostępu do usług oferowanych przez Administratora za pośrednictwem
              Serwisu, a także realizacji tych usług przez Administratora na rzecz Użytkownika.
            </li>
            <li>
              Komunikacji z Użytkownikiem. Administrator wykorzystuje dane osobowe Użytkownika w
              celu komunikacji z nim w spersonalizowany sposób. Komunikowane Użytkownikowi
              informacje dotyczą oferowanych produktów lub usług, bezpieczeństwa danych osobowych,
              aktualizacji sieci, przypomnień, ale również sugerowanych ofert Administratora lub
              jego partnerów. Komunikacja z Użytkownikiem dotyczy także obsługi Użytkownika. Dane
              osobowe wykorzystywane są w celu pomocy Użytkownikowi, rozwiązaniu problemów
              technicznych oraz odpowiedzi na jego skargi lub reklamacje.
            </li>
            <li>
              Przedstawiania Użytkownikowi ofert handlowych drogą elektroniczną. Celem korzystania z
              danych osobowych Użytkownika udostępnionych przez niego za pośrednictwem formularza
              kontaktowego dostępnego w Serwisie jest komunikacja marketingowa prowadzona przez
              Administratora w ramach prowadzonej działalności, w szczególności przedstawianie ofert
              handlowych Użytkownikowi drogą elektroniczną.
            </li>
            <li>
              Przedstawiania Użytkownikowi ofert handlowych w kontakcie telefonicznym. Celem
              korzystania z danych osobowych Użytkownika udostępnionych przez niego za pośrednictwem
              formularza kontaktowego dostępnego w Serwisie jest komunikacja marketingowa prowadzona
              przez Administratora w ramach prowadzonej działalności, w szczególności poprzez
              przedstawianie ofert handlowych Użytkownikowi w kontakcie telefonicznym.
            </li>
            <li>
              W celu zawarcia i realizacji ewentualnej umowy pomiędzy Użytkownikiem a
              Administratorem oraz obsługi Użytkownika jako klienta Administratora zgodnie z art. 6
              ust. 1 lit. b) RODO;
            </li>
            <li>
              W celu prowadzenia rozliczeń finansowych z Użytkownikiem będącym klientem
              Administratora tytułem realizacji ewentualnej umowy zawartej między stronami, a także
              ewentualnego dochodzenia roszczeń od Użytkownika będącego klientem w ramach prawnie
              uzasadnionego interesu Administratora zgodnie z art. 6 ust. 1 lit. f) RODO oraz
              spełnienia obowiązków prawnych Administratora wobec organów podatkowych na podstawie
              odrębnych przepisów zgodnie z art. 6 ust. 1 lit. c) RODO;
            </li>
            <li>
              W celu realizacji działań marketingowych Administratora w ramach prawnie uzasadnionego
              interesu Administratora w rozumieniu art. 6 ust. 1 lit. f) RODO, a także zgodnie z
              oświadczeniami woli dotyczącymi komunikacji marketingowej złożonymi wobec
              Administratora. Zgody udzielone w zakresie komunikacji marketingowej (np. na
              przesyłanie informacji handlowych drogą elektroniczną lub kontakt telefoniczny w
              celach marketingu bezpośredniego) mogą być wycofane w dowolnym czasie, bez wpływu na
              zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej
              cofnięciem;
            </li>
            <li>
              W celu realizacji obowiązków prawnych Administratora wobec Użytkownika określonych w
              RODO, w rozumieniu art. 6 ust. 1 lit. c) RODO.
            </li>
          </ol>
        </div>
        <div>
          <h3>IV. UDOSTĘPNIANIE DANYCH OSOBOWYCH</h3>
          <p>
            Dane osobowe Użytkownika nie są przekazywane przez Administratora podmiotom trzecim, z
            wyłączeniem adwokatów wskazanych na stronie AdwokaciOrla3.pl
          </p>
        </div>
        <div>
          <h3>V. PRAWA UŻYTKOWNIKA</h3>
          <ol>
            <li>
              <h3>Prawa Użytkownika</h3>
              <p>
                Użytkownik na każdym etapie przetwarzania jego danych zapewniony ma szereg uprawnień
                pozwalających mu uzyskać dostęp do swoich danych, weryfikację prawidłowości
                przetwarzania danych, ich korektę, jak również ma prawo zgłoszenia sprzeciwu wobec
                ich przetwarzania, może żądać usunięcia danych, ograniczenia przetwarzania lub
                przeniesienia danych. W przypadku chęci skorzystania przez Użytkownika z
                przysługujących mu praw jako podmiotu danych osobowych, może on się skontaktować z
                Administratorem za pomocą następujących danych kontaktowych: Adwokat Julianna
                Kędzierska ul. Orla 3, Lublin 20-022, julianna.kedzierska@gmail.com,
              </p>
            </li>
            <li>
              <h3>Prawo wniesienia skargi do organu nadzorczego</h3>
              <p>
                Użytkownik, którego dane osobowe są przetwarzane przez Administratora ma prawo
                wnieść skargę do organu nadzoru właściwego w sprawach ochrony danych osobowych
                (Prezesa Urzędu Ochrony Danych Osobowych).
              </p>
            </li>
          </ol>
        </div>
        <div>
          <h3>VI. PLIKI COOKIES</h3>
          <p>
            Administrator informuje, iż podczas korzystania z Serwisu w urządzeniu końcowym
            Użytkownika zapisywane są krótkie informacje tekstowe zwane „cookies”. Pliki „cookies”
            zawierają takie dane informatyczne jak: nazwa strony internetowej, z której pochodzą,
            czas przechowywania ich na urządzeniu końcowym Użytkownika, zapis parametrów i
            statystyki oraz unikalny numer. Pliki „cookies” są kierowane do serwera Serwisu za
            pośrednictwem przeglądarki internetowej zainstalowanej w urządzeniu końcowym
            Użytkownika. Pliki „cookies” wykorzystywane są w Serwisie w celu:
          </p>
          <ul>
            <li>
              utrzymania technicznej poprawności i ciągłości sesji pomiędzy serwerem Serwisu, a
              urządzeniem końcowym Użytkownika;
            </li>
            <li>
              optymalizacji korzystania przez Użytkownika ze stron internetowych Serwisu i
              dostosowania sposobu ich wyświetlania na urządzeniu końcowym Użytkownika;
            </li>
            <li>zapewnienia bezpieczeństwa korzystania z Serwisu;</li>
            <li>zbierania statystyk odwiedzin stron Serwisu;</li>
            <li>
              wyświetlania na urządzeniu końcowym Użytkownika treści reklamowych optymalnie
              dostosowanych do jego preferencji. W ramach Serwisu stosowane są wyłącznie „sesyjne”
              pliki „cookies”. „Sesyjne” pliki „cookies” są plikami ulegającymi automatycznemu
              usunięciu z urządzenia końcowego Użytkownika Serwisu po jego wylogowaniu z Serwisu lub
              po opuszczeniu przez niego stron internetowych Serwisu lub po wyłączeniu przeglądarki
              internetowej.
            </li>
          </ul>
          <p>Administrator informuje, że:</p>
          <ul>
            <li>
              przeglądarki internetowe domyślnie akceptują instalowanie plików „cookies” w
              urządzeniu końcowym Użytkownika. Każdy Użytkownik Serwisu może dokonać w dowolnym
              czasie zmiany ustawień dotyczących plików „cookies” w używanej przez niego
              przeglądarce internetowej w taki sposób, aby przeglądarka automatycznie blokowała
              obsługę plików „cookies”, bądź informowała Użytkownika o ich każdorazowym
              zamieszczeniu w jego urządzeniu końcowym. Szczegółowe informacje o możliwości i
              sposobach obsługi plików „cookies” dostępne są w ustawieniach przeglądarki
              internetowej stosowanej przez Użytkownika Serwisu.
            </li>
            <li>
              ograniczenie stosowania plików „cookies” przez Użytkownika może ujemnie wpłynąć na
              poprawność i ciągłość świadczenia Usług w Serwisie. Zawartość plików „cookies” nie
              pozwala na identyfikację tożsamości Użytkownika. Pliki „cookies” można uznać za dane
              osobowe jedynie w powiązaniu z innymi danymi identyfikującymi tożsamość,
              udostępnionymi Administratorowi przez Użytkownika w ramach korzystania z Serwisu.
              Dostęp do plików „cookies” przetwarzanych przez serwer Serwisu posiada wyłącznie
              Administrator.
            </li>
          </ul>
        </div>
        <div>
          <h3>VII. INNE WAŻNE INFORMACJE</h3>
          <ol>
            <li>
              <h4>Ochrona bezpieczeństwa danych osobowych</h4>
              <p>
                Administrator wprowadza odpowiednie środki mające na celu zapewnienie bezpieczeństwa
                danych osobowych Użytkownika. Bezpieczne korzystanie z Serwisu zapewniają stosowane
                systemy oraz procedury chroniące przed dostępem oraz ujawnieniem danych osobom
                niepożądanym. Ponadto stosowane przez Administratora systemy oraz procesy są
                regularnie monitorowane w celu wykrycia ewentualnych zagrożeń. Pozyskane przez
                Administratora dane osobowe przechowywane są w systemach komputerowych, do których
                dostęp jest ściśle ograniczony.
              </p>
            </li>
            <li>
              <h4>Przechowywanie danych osobowych</h4>
              <p>
                Okres przechowywania danych osobowych Użytkowników uzależniony jest od celów
                przetwarzania przez Administratora danych. Administrator przechowuje dane osobowe
                przez taki okres, jaki jest konieczny do osiągnięcia określonych celów, tj.: przez
                okres prowadzenia działalności gospodarczej przez Administratora. W każdym z
                powyższych przypadków, po upływie niezbędnego okresu przetwarzania, dane mogą być
                przetwarzane tylko w celu dochodzenia roszczeń na tle łączących strony relacji do
                czasu ostatecznego rozstrzygnięcia tych roszczeń na drodze prawnej. Dane osobowe
                Użytkowników są przechowywane w bazie danych Administratora, w której zastosowano
                środki techniczne i organizacyjne zapewniające ochronę przetwarzanych danych zgodne
                z wymaganiami określonymi w obowiązujących przepisach prawa.
              </p>
            </li>
            <li>
              <h4>Zmiany polityki prywatności</h4>
              <p>
                W celu uaktualnienia informacji zawartych w niniejszej Polityce prywatności oraz jej
                zgodności z obowiązującymi przepisami prawa, niniejsza Polityka prywatności może
                ulec zmianie. W przypadku zmiany treści Polityki prywatności, zmieniona zostanie
                data jej aktualizacji wskazana na końcu jej tekstu. W celu zasięgnięcia informacji o
                sposobie ochrony danych osobowych, Administrator rekomenduje Użytkownikom regularne
                zapoznawanie się z postanowieniami Polityki Prywatności.
              </p>
            </li>
            <li>
              <h4>Informacje kontaktowe</h4>
              <p>
                W celu uzyskania jakichkolwiek informacji dotyczących niniejszej Polityki
                Prywatności, Użytkownik może skontaktować się z Administratorem danych osobowych.
              </p>
            </li>
          </ol>
        </div>
        <div>
          <h3>Obowiązek informacyjny</h3>
          <p>
            Administratorem danych osobowych Użytkownika udostępnionych przy rejestracji
            konta/profilu Użytkownika w Serwisie, czyli podmiotem decydującym o celach i sposobach
            ich przetwarzania, jest Adwokat Julianna Kędzierska ul. Orla 3, Lublin 20-022,
            julianna.kedzierska@gmail.com, NIP 9462583101, (dalej jako Administrator).
          </p>
          <p>
            Podane przez Użytkownika dane osobowe są przetwarzane przez Administratora na podstawie
            wyrażonej przez Użytkownika przy rejestracji konta/profilu zgody,w rozumieniu art. 6
            ust. 1 lit. a) Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27
            kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych
            osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy
            95/46/WE – zwanego dalej RODO.
          </p>
          <p>
            Podanie danych osobowych jest dobrowolne. Użytkownik ma prawo do cofnięcia wyrażonej
            zgody w dowolnym momencie poprzez kontakt z Administratorem
            julianna.kedzierska@gmail.com, bez wpływu na zgodność z prawem przetwarzania, którego
            dokonano na podstawie zgody przed jej cofnięciem. W przypadku cofnięcia zgody,
            korzystanie z konta/profilu Użytkownika może być niemożliwe lub utrudnione.
          </p>
          <p>
            Użytkownikowi przysługuje prawo dostępu do swoich danych osobowych w każdym czasie, ich
            sprostowania, usunięcia lub ograniczenia przetwarzania oraz prawo do wniesienia
            sprzeciwu wobec przetwarzania, a także prawo do przenoszenia danych.
          </p>
          <p>Administrator przetwarza dane osobowe Użytkownika Serwisu w następujących celach:</p>
          <ol>
            <li>Realizacji na rzecz Użytkownika usług dostępnych za pośrednictwem Serwisu;</li>
            <li>
              Komunikacji z Użytkownikiem, w szczególności obsługi reklamacji dotyczących produktów
              lub usług Administratora;
            </li>
            <li>Przedstawiania Użytkownikowi ofert handlowych drogą elektroniczną;</li>
            <li>Przedstawiania Użytkownikowi ofert handlowych w kontakcie telefonicznym;</li>
            <li>
              Umożliwienia Użytkownikowi przesyłania komentarzy lub opinii dotyczących produktów lub
              usług Administratora.
            </li>
          </ol>
          <p>
            Administrator może przetwarzać dane osobowe Użytkownika podane w formularzu kontaktowym
            również w następujących celach:
          </p>
          <ol>
            <li>
              w celu zawarcia i realizacji ewentualnej umowy pomiędzy Użytkownikiem a
              Administratorem oraz obsługi Użytkownika jako klienta Administratora zgodnie z art. 6
              ust. 1 lit. b) RODO;
            </li>
            <li>
              w celu prowadzenia rozliczeń finansowych z Użytkownikiem będącym klientem
              Administratora tytułem realizacji ewentualnej umowy zawartej między stronami, a także
              ewentualnego dochodzenia roszczeń od Użytkownika będącego klientem w ramach prawnie
              uzasadnionego interesu Administratora zgodnie z art. 6 ust. 1 lit. f) RODO oraz
              spełnienia obowiązków prawnych Administratora wobec organów podatkowych na podstawie
              odrębnych przepisów zgodnie z art. 6 ust. 1 lit. c) RODO;
            </li>
            <li>
              w celu realizacji działań marketingowych Administratora w ramach prawnie uzasadnionego
              interesu Administratora w rozumieniu art. 6 ust. 1 lit. f) RODO, a także zgodnie z
              oświadczeniami woli dotyczącymi komunikacji marketingowej złożonymi wobec
              Administratora. Zgody udzielone w zakresie komunikacji marketingowej (np. na
              przesyłanie informacji handlowych drogą elektroniczną lub kontakt telefoniczny w
              celach marketingu bezpośredniego) mogą być wycofane w dowolnym czasie, bez wpływu na
              zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej
              cofnięciem;
            </li>
            <li>
              w celu realizacji obowiązków prawnych Administratora wobec Użytkownika określonych w
              RODO, w rozumieniu art. 6 ust. 1 lit. c) RODO. Więcej informacji dotyczących
              powyższych celów przetwarzania danych osobowych Użytkownika znajduje się w Polityce
              prywatności opublikowanej w Serwisie.
            </li>
          </ol>
        </div>
        <div>
          <p>
            Administrator przechowuje dane osobowe przez taki okres, jaki jest konieczny do
            osiągnięcia określonych celów, tj.: przez okres prowadzenia działalności gospodarczej
            przez Administratora. W przypadku jakichkolwiek pytań lub problemów związanych z
            przetwarzaniem danych osobowych należy kontaktować się z Administratorem pod
            następującym adresem:
          </p>
          <p>
            Adwokat Julianna Kędzierska <br />
            ul. Orla 3, Lublin 20-022, <br />
            julianna.kedzierska@gmail.com.
          </p>
          <p>
            Użytkownik ma prawo do wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych.
          </p>
        </div>
      </Layout>
    </MainTemplate>
  )
}

export default PrivacyPolicyPage
